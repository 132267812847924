
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { Keypair, PublicKey } from '@solana/web3.js';
import ConfigPane from '@/components/ConfigPane.vue';
import FarmerDisplay from '@/components/gem-farm/FarmerDisplay.vue';
import Vault from '@/components/gem-bank/Vault.vue';
import { INFT } from '@/common/web3/NFTget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
import { initGemFarm, GemFarm } from '@/common/gem-farm';
import { stringifyPKsAndBNs } from '@/client';
import { findFarmerPDA } from '@/client/gem-farm';

export default defineComponent({
  components: { Vault, FarmerDisplay, ConfigPane, Loading },

  created() {
    setInterval(() => {
      this.currentTS = Date.now()
    }, 1000)
  },

  setup() {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    let gf: GemFarm;
    watch([wallet, cluster], async () => {
      await freshStart();
    });

    // --------------------------------------- Locked up  value
    const magicEdenFloor = ref<Number>();
    const solPrice = ref<Number>();

    // --------------------------------------- loading

    //current walet/vault state
    const isLoading = ref<boolean>(false);

    // --------------------------------------- farmer details
    const farm = ref<string>(process.env.VUE_APP_GEM_FARM_PK || "");
    const farmAcc = ref<any>();

    const farmerIdentity = ref<string>();
    const farmerAcc = ref<any>();
    const farmerState = ref<string>();

    const availableA = ref<string>();
    const availableB = ref<string>();

    //needed in case we switch in from another window
    onMounted(async () => {
      await freshStart();
      const meRes = (await axios.get('https://api-mainnet.magiceden.io/rpc/getCollectionEscrowStats/blocksmith_labs?edge_cache=true')).data
      const solPriceData = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd&include_last_updated_at=true');
      const solPriceDataResponse = await solPriceData.json();
      // console.log(meRes)
      magicEdenFloor.value = meRes.results.floorPrice / 1000000000
      solPrice.value = solPriceDataResponse['solana'].usd;
    });

    const updateAvailableRewards = async () => {
      availableA.value = farmerAcc.value.rewardA.accruedReward
        .sub(farmerAcc.value.rewardA.paidOutReward)
        .toString();
      availableB.value = farmerAcc.value.rewardB.accruedReward
        .sub(farmerAcc.value.rewardB.paidOutReward)
        .toString();
    };

    const fetchFarm = async () => {
      farmAcc.value = await gf.fetchFarmAcc(new PublicKey(farm.value!));
      console.log(
        `farm found at ${farm.value}:`,
        stringifyPKsAndBNs(farmAcc.value)
      );
    };

    const fetchFarmer = async () => {

      const userPubkey = getWallet()!.publicKey;
      if (!userPubkey) return;

      const [farmerPDA] = await findFarmerPDA(
        new PublicKey(farm.value!),
        userPubkey
      );
      farmerIdentity.value = getWallet()!.publicKey?.toBase58();
      farmerAcc.value = await gf.fetchFarmerAcc(farmerPDA);
      farmerState.value = gf.parseFarmerState(farmerAcc.value);
      await updateAvailableRewards();
      console.log(
        `farmer found at ${farmerIdentity.value}:`,
        stringifyPKsAndBNs(farmerAcc.value)
      );
    };

    const freshStart = async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
        farmerIdentity.value = getWallet()!.publicKey?.toBase58();

        //reset stuff
        farmAcc.value = undefined;
        farmerAcc.value = undefined;
        farmerState.value = undefined;
        availableA.value = undefined;
        availableB.value = undefined;

        try {
          await fetchFarm();
          await fetchFarmer();
        } catch (e) {
          console.log(e)
          console.log(`farm with PK ${farm.value} not found :(`);
        }
      }
    };

    const initFarmer = async () => {
      await gf.initFarmerWallet(new PublicKey(farm.value!));
      await fetchFarmer();
    };

    // --------------------------------------- staking

    const claim = async () => {
      isLoading.value = true;
      try {
        await gf.claimWallet(
          new PublicKey(farm.value!),
          new PublicKey(farmAcc.value.rewardA.rewardMint!),
          new PublicKey(farmAcc.value.rewardB.rewardMint!)
        );
      } catch (err) {
        console.log(err)
      } finally {
        await fetchFarmer();
        isLoading.value = false;

      }
    };

    const handleRefreshFarmer = async () => {
      await fetchFarmer();
    };

    // --------------------------------------- adding extra gem
    const selectedNFT = ref<INFT>();

    const resetSelectedNft = () => {
      selectedNFT.value = undefined
    }

    const handleNewSelectedNFT = (newSelectedNFT: INFT) => {
      console.log(`selected NFT`);
      selectedNFT.value = newSelectedNFT;
    };

    const addSingleGem = async (
      gemMint: PublicKey,
      gemSource: PublicKey,
      creator: PublicKey,
      isPnft: boolean
    ) => {
      await gf.flashDepositWallet(
        new PublicKey(farm.value!),
        '1',
        gemMint,
        gemSource,
        creator,
        isPnft,
      );
      resetSelectedNft();
    };

    const flashDepositSelectedNFT = async (newSelectedNFT: INFT) => {
      selectedNFT.value = newSelectedNFT;
      await addGem();
    };

    const addGem = async () => {
      isLoading.value = true;
      try {
        const anySelectNFT = (selectedNFT.value as INFT)
        const creator = anySelectNFT.metadata.creators[0].address;
        const isPnft = anySelectNFT.metadata.programmableConfig ? true : false;

        console.log('creator is', creator.toBase58());
        await addSingleGem(anySelectNFT.mint, anySelectNFT.owner!, creator, isPnft);

        console.log(`added another gems into staking vault`);
      } catch (err) {
        console.log(err)
      } finally {
        await fetchFarm();
        await fetchFarmer();
        isLoading.value = false;
      }
    };

    return {
      currentTS: Date.now(),
      isLoading,
      fullPage: true,
      loader: 'bars',
      wallet,
      farm,
      farmAcc,
      farmer: farmerIdentity,
      farmerAcc,
      farmerState,
      availableA,
      availableB,
      initFarmer,
      claim,
      handleRefreshFarmer,
      flashDepositSelectedNFT,
      selectedNFT,
      handleNewSelectedNFT,
      addGem,
      fetchFarm,
      fetchFarmer,
      magicEdenFloor,
      solPrice
    };
  },
});
