import { Connection, PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Metaplex, Nft } from '@metaplex-foundation/js';
// @ts-ignore: Unreachable code error
import { mints } from '../util/mints.js';
import '../util/mint_rank.json';

export interface INFT {
  mint: PublicKey;
  metadata: Nft;
  owner?: PublicKey;
}

async function getTokensByOwner(owner: PublicKey, conn: Connection) {
  const tokens = await conn.getParsedTokenAccountsByOwner(owner, {
    programId: TOKEN_PROGRAM_ID,
  });
  // initial filter - only tokens with 0 decimals & of which 1 is present in the wallet
  return tokens.value
    .filter((t) => {
      const amount = t.account.data.parsed.info.tokenAmount;
      return (
        amount.decimals === 0 &&
        amount.uiAmount === 1 &&
        mints.includes(t.account.data.parsed.info.mint)
      );
    })
    .map((t) => {
      return { pubkey: t.pubkey, mint: t.account.data.parsed.info.mint };
    });
}

async function getNFTMetadata(
  mintAddress: string,
  conn: Connection,
  pubkey?: string
): Promise<INFT | undefined> {

  // console.log('Pulling metadata for:', mint);
  try {
    const mint = new PublicKey(mintAddress);
    const metaplex = new Metaplex(conn);
    const metadata = (await metaplex.nfts().findByMint({
      mintAddress: mint
    })) as Nft

    return {
      owner: pubkey ? new PublicKey(pubkey) : undefined,
      mint,
      metadata,
    };
  } catch (e) {
    console.log(`failed to pull metadata for token ${mintAddress}`);
  }
}

export async function getNFTMetadataForMany(
  tokens: any[],
  conn: Connection
): Promise<INFT[]> {
  const promises: Promise<INFT | undefined>[] = [];
  tokens.forEach((t) => promises.push(getNFTMetadata(t.mint, conn, t.pubkey)));
  const nfts = (await Promise.all(promises)).filter((n) => !!n);
  console.log(`found ${nfts.length} metadatas`);

  return nfts as INFT[];
}

export async function getNFTsByOwner(
  owner: PublicKey,
  conn: Connection
): Promise<INFT[]> {
  const tokens = await getTokensByOwner(owner, conn);
  console.log(`found ${tokens.length} blocksmithlabs`);

  return await getNFTMetadataForMany(tokens, conn);
}
