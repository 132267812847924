
import { defineComponent, onMounted, ref, watch } from 'vue';
import NFTGrid from '@/components/gem-bank/NFTGrid.vue';
import ArrowButton from '@/components/ArrowButton.vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import {
  getNFTMetadataForMany,
  getNFTsByOwner,
  INFT,
} from '@/common/web3/NFTget';
import { PublicKey, Transaction, TransactionInstruction } from '@solana/web3.js';
import { createAssociatedTokenAccountInstruction, getAccount } from '@solana/spl-token-036';
import { AnchorProvider, BN } from '@coral-xyz/anchor';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { initGemBank, GemBank } from '@/common/gem-bank';
import { initGemFarm, GemFarm } from '@/common/gem-farm';
import { buildAndSendTx, createAndSendV0Tx, getTxSize } from '@/client/gem-common/pnft';
import { findFarmerPDA } from '@/client';
import { createRevokeInstruction } from '@solana/spl-token-036';

export default defineComponent({
  components: { ArrowButton, NFTGrid, Loading },
  props: {
    vault: String,
    farmerAcc: Object,
    farmerState: String
  },
  emits: ['selected-wallet-nft', 'fetchFarm', 'fetchFarmer', 'flash-deposit-wallet-nft'],
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    // --------------------------------------- loading

    //current walet/vault state
    const isLoading = ref<boolean>(false);

    // --------------------------------------- state

    //current walet/vault state
    const currentWalletNFTs = ref<INFT[]>([]);
    const currentVaultNFTs = ref<INFT[]>([]);
    //initial walet/vault state
    const initialWalletNFTs = ref<INFT[]>([]);
    const initialVaultNFTs = ref<INFT[]>([]);
    //selected but not yet moved over in FE
    const selectedNFT = ref<INFT>();
    //selected wallet or vault
    const selectedLocation = ref<String>();

    // --------------------------------------- populate initial nfts

    const populateWalletNFTs = async () => {
      // zero out to begin with
      currentWalletNFTs.value = [];
      initialWalletNFTs.value = [];

      if (getWallet()) {
        currentWalletNFTs.value = await getNFTsByOwner(
          getWallet()!.publicKey!,
          getConnection()
        );
        initialWalletNFTs.value = [...currentWalletNFTs.value];
      }
    };

    const populateVaultNFTs = async () => {
      // zero out to begin with
      currentVaultNFTs.value = [];
      initialVaultNFTs.value = [];

      const foundGDRs = await gb.fetchAllGdrPDAs(vault.value);
      if (foundGDRs && foundGDRs.length) {
        gdrs.value = foundGDRs.map((gdr => gdr.publicKey));
        console.log(`found a total of ${foundGDRs.length} gdrs`);

        const mints = foundGDRs.map((gdr: any) => {
          return { mint: gdr.account.gemMint };
        });
        currentVaultNFTs.value = await getNFTMetadataForMany(
          mints,
          getConnection()
        );

        console.log(currentVaultNFTs.value)

        initialVaultNFTs.value = [...currentVaultNFTs.value];
        console.log(
          `populated a total of ${currentVaultNFTs.value.length} vault NFTs`
        );
      }
    };

    const updateVaultState = async () => {
      vaultAcc.value = await gb.fetchVaultAcc(vault.value!);
      bank.value = vaultAcc.value.bank;
    };

    watch([wallet, cluster], async () => {
      gb = await initGemBank(getConnection(), getWallet()!);
      gf = await initGemFarm(getConnection(), getWallet()!);

      //populate wallet + vault nfts
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    });

    onMounted(async () => {
      gb = await initGemBank(getConnection(), getWallet()!);
      gf = await initGemFarm(getConnection(), getWallet()!);

      //prep vault + bank variables
      vault.value = new PublicKey(props.vault!);
      await updateVaultState();

      //populate wallet + vault nfts
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    });

    // --------------------------------------- moving nfts

    const resetSelectedNft = () => {
      selectedLocation.value = undefined
      selectedNFT.value = undefined
    }

    const depositNFTOnChain = async (e: any) => {
      const selectedWalletNFT = e.nft
      const anySelectNFT = (selectedWalletNFT as INFT)

      if (props.farmerState === "staked") {
        ctx.emit('flash-deposit-wallet-nft', selectedWalletNFT);
      } else {
        const creator = anySelectNFT.metadata.creators[0].address;
        const isPnft = anySelectNFT.metadata.programmableConfig ? true : false;

        await depositGem(anySelectNFT.mint, creator, anySelectNFT.owner!, isPnft);
      }
    }

    const depositAllNFTOnChain = async () => {
      isLoading.value = true;
      try {
        const farm = ref<string>(process.env.VUE_APP_GEM_FARM_PK || "");

        /*
        const { txSig } = await gf.stakeAllGemsWallet(
          bank.value,
          vault.value,
          new BN(1),
          initialWalletNFTs.value.map(x => x.mint),
          initialWalletNFTs.value.map(x => x.owner),
          initialWalletNFTs.value.map(x => {
            return x.metadata.creators[0].address
          }),
          new PublicKey(farm.value!),
          props.farmerState === "staked"
        );
        console.log('deposit done', txSig);
        */
      } catch (err) {
        console.log(err)
      } finally {
        ctx.emit('fetchFarm');
        ctx.emit('fetchFarmer');
        await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
        isLoading.value = false;
      }
    };


    const withdrawNFTOnChain = async (e: any) => {
      const selectedVaultNFT = e.nft;
      const anySelectNFT = (selectedVaultNFT as INFT);
      console.log(anySelectNFT)

      const gemsStaked = initialVaultNFTs.value.length;
      const isPnft = anySelectNFT.metadata.programmableConfig ? true : false;
      await withdrawGem(anySelectNFT.mint, gemsStaked, isPnft);
    }

    const withdrawAllNFTOnChain = async () => {
      isLoading.value = true;
      try {
        const farm = ref<string>(process.env.VUE_APP_GEM_FARM_PK || "");

        /*
        const { txSig } = await gf.unstakeAllGemsWallet(
          bank.value,
          vault.value,
          new BN(1),
          initialVaultNFTs.value.map(x => x.mint),
          new PublicKey(farm.value!)
        );
        console.log('withdrawal done', txSig);
        */
      } catch (err) {
        console.log(err)
      } finally {
        ctx.emit('fetchFarm');
        ctx.emit('fetchFarmer');
        await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
        isLoading.value = false;
      }
    };

    const handleNFTWalletSelected = (e: any) => {
      if (e.selected) {
        selectedLocation.value = "wallet"
      } else {
        selectedLocation.value = undefined
      }
      handleNFTSelected(e)
    }
    const handleNFTVaultSelected = (e: any) => {
      if (e.selected) {
        selectedLocation.value = "vault"
      } else {
        selectedLocation.value = undefined
      }
      handleNFTSelected(e)
    }

    const handleNFTSelected = (e: any) => {
      if (e.selected) {
        selectedNFT.value = e.nft
        console.log(selectedNFT.value)
        if (selectedLocation.value === "wallet") {
          ctx.emit('selected-wallet-nft', selectedNFT.value);
        } else {
          ctx.emit('selected-wallet-nft', null);
        }
      } else {
        selectedNFT.value = undefined
        ctx.emit('selected-wallet-nft', null);
      }
    };

    //todo jam into single tx
    const moveNFTOnChain = async () => {
      const anySelectNFT = (selectedNFT.value as INFT)
      const isPnft = anySelectNFT.metadata.programmableConfig ? true : false;

      if (selectedLocation.value !== "wallet") {
        const gemsStaked = initialVaultNFTs.value.length;
        await withdrawGem(anySelectNFT.mint, gemsStaked, isPnft);
      } else {
        const creator = anySelectNFT.metadata.creators[0].address;
        await depositGem(anySelectNFT.mint, creator, anySelectNFT.owner!, isPnft);
      }

      resetSelectedNft();
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    };

    // --------------------------------------- gem farm

    let gf: GemFarm;

    // --------------------------------------- gem bank

    let gb: GemBank;

    const bank = ref<PublicKey>();
    const vault = ref<PublicKey>();
    const vaultAcc = ref<any>();
    const gdrs = ref<PublicKey[]>([]);
    const vaultLocked = ref<boolean>(false);

    const depositGem = async (
      mint: PublicKey,
      creator: PublicKey,
      source: PublicKey,
      isPnft: boolean,
    ) => {
      const farm = new PublicKey(process.env.VUE_APP_GEM_FARM_PK || "");

      const { ixs } = await gb.depositGemWallet(
        bank.value!,
        vault.value!,
        new BN(1),
        mint,
        source,
        creator,
        isPnft
      );

      const stakeIx = await gf.stakeWallet(farm);
      if (ixs && stakeIx) {
        ixs.push(stakeIx);

        const txSig = await buildAndSendTx({
          provider: gf.provider as AnchorProvider,
          ixs
        })

        console.log('deposit done', txSig);

        ctx.emit('fetchFarm');
        ctx.emit('fetchFarmer');
        await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
      }
    };

    const withdrawGem = async (
      mint: PublicKey,
      gemsStaked: number,
      isPnft: boolean,
    ) => {
      const owner = gf.wallet.publicKey;
      const farm = new PublicKey(process.env.VUE_APP_GEM_FARM_PK || "");
      const farmerPda = (await findFarmerPDA(farm, owner))[0];
      const farmerAcc = await gf.fetchFarmerAcc(farmerPda);

      let ixs: TransactionInstruction[] = [];

      let unstakeIxs: TransactionInstruction[] = [];
      if (farmerAcc.state.staked) {
        const unstakeIx = await gf.unstakeWallet(farm);
        if (!unstakeIx) {
          return;
        }

        unstakeIxs = [unstakeIx, unstakeIx];
        ixs.push(...unstakeIxs);
      }

      let revokeIx, createAtaIx;
      const destinationAta = await gf.findATA(mint, owner);
      try {
        const destAtaAcc = await getAccount(gf.provider.connection, destinationAta);
        if (destAtaAcc.delegate) {
          revokeIx = createRevokeInstruction(
            destinationAta,
            owner,
          );
          ixs.push(revokeIx);
        }
      }
      catch (ex) {
        console.log(ex);
        createAtaIx = createAssociatedTokenAccountInstruction(owner, destinationAta, owner, mint);
        ixs.push(createAtaIx);
      }

      const { ixs: withdrawIxs } = await gb.withdrawGemWallet(
        bank.value!,
        vault.value!,
        new BN(1),
        mint,
        isPnft,
      );
      ixs.push(...withdrawIxs);

      let stakeIx;
      if (gemsStaked > 1) {
        stakeIx = await gf.stakeWallet(farm);
        if (stakeIx) {
          ixs.push(stakeIx);
        }
      }

      // Check transaction size
      const txSize = await getTxSize(gf.provider as AnchorProvider, ixs);
      if (txSize > 1200) {
        if (unstakeIxs.length > 0) {
          const txHash1 = await createAndSendV0Tx(gf.provider as AnchorProvider, unstakeIxs);
          console.log('unstake done', txHash1);
        }

        ixs = [];
        if (createAtaIx) {
          ixs = [createAtaIx];
        }

        if (revokeIx) {
          ixs = [revokeIx, ...withdrawIxs];
        }
        else {
          ixs = [...ixs, ...withdrawIxs];
        }

        if (stakeIx) {
          ixs.push(stakeIx);
        }

        const txHash2 = await createAndSendV0Tx(gf.provider as AnchorProvider, ixs);
        console.log('withdraw done', txHash2);
      }
      else {
        const txHash = await createAndSendV0Tx(gf.provider as AnchorProvider, ixs);
        console.log('withdraw done', txHash);
      }

      ctx.emit('fetchFarm');
      ctx.emit('fetchFarmer');
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    };

    // --------------------------------------- return

    return {
      isLoading,
      fullPage: true,
      loader: 'bars',
      wallet,
      initialWalletNFTs,
      initialVaultNFTs,
      selectedNFT,
      selectedLocation,
      handleNFTSelected,
      withdrawNFTOnChain,
      depositNFTOnChain,
      handleNFTVaultSelected,
      handleNFTWalletSelected,
      withdrawAllNFTOnChain,
      depositAllNFTOnChain,
      moveNFTOnChain,
      bank,
      // eslint-disable-next-line vue/no-dupe-keys
      vault,
      vaultLocked,
    };
  },
});
