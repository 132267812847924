
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    schedule: Object,
    gems: Number,
    reward: Object,
    multipler: Number,
  },
  setup() {
    return {};
  },
});
