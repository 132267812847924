
import { defineComponent } from 'vue';
import FixedScheduleDisplay from '@/components/gem-farm/FixedScheduleDisplay.vue';
import { parseDate } from '@/common/util';
import numeral from 'numeral';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

export default defineComponent({
  components: { FixedScheduleDisplay },
  props: {
    reward: Object,
    farmReward: Object,
    gems: Number,
    title: String,
  },
  data() {
    return {
      currentTS: Date.now(),
      LAMPORTS_PER_SOL: 1000000000,
    };
  },

  created() {
    setInterval(() => {
      this.currentTS = Date.now();
    }, 1000);
  },
  setup(props) {
    const tier1Tenure = 1; // in months
    const baseTierReward = 4;
    const tier1Reward = 4;

    const parseRewardType = (reward: any): string => {
      //returns "variable" or "fixed"
      return Object.keys(reward.rewardType)[0];
    };

    const getEstimatedRewards = (
      reward: any,
      gems: any,
      currentTS: number
    ): String => {
      const schedule = reward.fixedRate.promisedSchedule;
      return (
        (reward.accruedReward - reward.paidOutReward) / Math.pow(10, 9) +
        parseInt(gems) *
        ((currentTS / 1000 - reward.fixedRate.lastUpdatedTs)) *
        (schedule.baseRate.toNumber() / schedule.denominator.toNumber())
        / LAMPORTS_PER_SOL
      ).toFixed(4);
    };

    const promisedReward = (reward: any, currentTS: number): number => {
      return getTimeStampDifference(
        currentTS,
        reward.fixedRate.beginStakingTs * 1000
      ) < tier1Tenure
        ? baseTierReward
        : tier1Reward;
    };

    const getTimeStampDifference = (
      currentTs: number,
      stakingTs: number
    ): number => {
      const currentDate = new Date(currentTs);
      const stakingDate = new Date(stakingTs);
      return (
        currentDate.getMonth() -
        stakingDate.getMonth() +
        12 * (currentDate.getFullYear() - stakingDate.getFullYear())
      );
    };

    return {
      parseRewardType,
      parseDate,
      numeral,
      getEstimatedRewards,
      promisedReward,
    };
  },
});
