import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7aea8ecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex sm:p-5 p-2 w-fit mb-10 rounded justify-center items-center mx-auto sm:btn-container-mobile btn-container" }
const _hoisted_2 = { class: "flex justify-center" }
const _hoisted_3 = { class: "flex flex-col md:flex-row items-stretch space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loadig = _resolveComponent("loadig")!
  const _component_NFTGrid = _resolveComponent("NFTGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loadig, {
      active: _ctx.isLoading,
      "is-full-page": _ctx.fullPage,
      loader: _ctx.loader
    }, null, 8, ["active", "is-full-page", "loader"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NFTGrid, {
        title: "Your Wallet",
        emptyText: "You have no Smiths in your wallet",
        class: "flex-1",
        nfts: _ctx.initialWalletNFTs,
        selectedNFT: _ctx.selectedNFT,
        staked: false,
        onSelected: _ctx.depositNFTOnChain
      }, null, 8, ["nfts", "selectedNFT", "onSelected"]),
      (_ctx.bank && _ctx.vault)
        ? (_openBlock(), _createBlock(_component_NFTGrid, {
            key: 0,
            title: "Staked Smiths",
            emptyText: "You have no Smiths staked",
            class: "flex-1",
            nfts: _ctx.initialVaultNFTs,
            selectedNFT: _ctx.selectedNFT,
            staked: true,
            onSelected: _ctx.withdrawNFTOnChain
          }, null, 8, ["nfts", "selectedNFT", "onSelected"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}