import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3573b587"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col rounded-lg shadow-lg w-52 m-3 bg-sections-bslgray" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "flex mt-2 flex-col" }
const _hoisted_4 = { class: "flex mb-3" }
const _hoisted_5 = { class: "w-1/2 text-xs text-center text-base text-gray-500" }
const _hoisted_6 = { class: "block text-white" }
const _hoisted_7 = { class: "w-1/2 text-xs text-center text-base text-gray-500" }
const _hoisted_8 = { class: "block text-white" }
const _hoisted_9 = { class: "flex mb-3 justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "rounded-t-lg",
      src: _ctx.nftImg,
      alt: _ctx.nft.metadata.name
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createTextVNode(" ID "),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.nft.metadata.name), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createTextVNode(" RANK "),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.mint_rank[_ctx.nft.mint.toBase58()]), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.staked)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "w-full text-base bg-transparent mx-2 hover:bg-buttons-red text-black-700 hover:text-white py-1 px-2 border border-buttons-red hover:border-transparent rounded",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectNFT && _ctx.selectNFT(...args)))
            }, " UNSTAKE "))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "w-full text-base bg-transparent mx-2 hover:bg-buttons-green text-black-700 hover:text-white py-1 px-2 border border-buttons-green hover:border-transparent rounded",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectNFT && _ctx.selectNFT(...args)))
            }, " STAKE "))
      ])
    ])
  ]))
}